<style scoped type="text/css">
svg {
	height: 100%;
	width: 100%;
}

#arrow {
	transition: 5s ease-out;
	transform-origin: 59.6% 106.5%;
}

@media screen and (max-width: 700px) {
	svg {
		width: 100%;
	}
}

@media screen and (max-width: 575px) {
	svg {
		width: 100%;
	}
}

@media screen and (max-width: 445px) {
	svg {
		width: 100%;
	}
}

@media screen and (max-width: 399px) {
	svg {
		width: 100%;
	}
}

@-moz-document url-prefix() {
	svg {
		width: 50%;
	}
}
</style>

<template>
	<!-- Generator: Adobe Illustrator 25.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
	<svg
		width="100%"
		height="100%"
		viewBox="0 0 1000 500"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		xml:space="preserve"
		xmlns:serif="http://www.serif.com/"
		style="
			fill-rule: evenodd;
			clip-rule: evenodd;
			stroke-linejoin: round;
			stroke-miterlimit: 2;
		"
	>
		<g transform="matrix(0.638978,0,0,0.638978,179.232,32.0619)">
			<g id="boog" transform="matrix(1.00007,0,0,1.00007,-690.69,-399.067)">
				<g>
					<path
						id="pk1Z0q7hb"
						d="M1157.5,914.1C1156.8,912.9 978,376.5 978,376.4C978,376 999.3,371.2 1012,368.6C1115.6,347.6 1220,344.6 1321,359.6C1415.79,373.678 1444.71,386.19 1445.31,386.79C1445.51,386.99 1222,910.3 1222,911.3C1222,914 1158.3,915.3 1157.5,914.1Z"
						style="fill: rgb(237, 125, 49); fill-rule: nonzero"
					/>
				</g>
				<g>
					<path
						id="p1deTq1Is"
						d="M1280.4,950C1279.9,949.9 1224.9,915.6 1224.1,915.1C1222.5,914 1445.7,387.3 1446,387C1446.05,386.946 1446,387 1446,387C1559.7,419.8 1668.3,478.9 1753.5,554.4C1824.94,617.706 1920.8,780.759 1921,780.959C1921.2,781.059 1280.8,950 1280.4,950Z"
						style="fill: rgb(241, 208, 81); fill-rule: nonzero"
					/>
				</g>
				<g>
					<path
						id="pa9PeTExh"
						d="M1100.72,951.571C1094.11,946.376 618.5,572.8 617.2,571.6L614.9,569.4L627.7,557.9C699.8,493 785.6,441.7 881.5,406C915.1,393.5 974.2,375.8 976.1,377.7C976.9,378.5 1154.5,911 1155.2,914.5C1155.4,915.8 1102.49,954.228 1100.72,951.571Z"
						style="fill: rgb(237, 96, 49); fill-rule: nonzero"
					/>
				</g>
				<g transform="matrix(1.0016,0,0,1.0016,-2.05425,-1.25238)">
					<path
						id="p11jeAOPLS"
						d="M1307.4,1021C1307.4,1021 1282.2,951.4 1282.7,951C1283.09,950.611 1920.02,781.6 1920.02,781.6C1936.51,814.405 1952.7,860.8 1960.9,898C1968.6,933 1974,978.9 1974,1009.8L1974,1021L1307.4,1021Z"
						style="fill: rgb(0, 176, 80); fill-rule: nonzero"
					/>
				</g>
				<g transform="matrix(1.00129,0,0,1.00129,-1.42327,0.866031)">
					<path
						id="p186t4dUjk"
						d="M1077.7,1019L411,1019L411,1001.1C411,992.2 412.6,970.5 414.5,954C419.6,910.2 431.2,861.9 446.5,820.5C452.2,805.3 454.7,799 461.5,783C471.4,759.9 497.3,712.8 513,689.5C569.452,605.722 613.34,569.642 613.34,569.642C613.34,569.642 1099.6,951 1100.5,951.5C1102.1,952.5 1077.7,1019 1077.7,1019Z"
						style="fill: rgb(192, 0, 0); fill-rule: nonzero"
					/>
				</g>
			</g>
			<g
				transform="matrix(1.99996,0.0121471,-0.00884282,1.53075,-684.187,-209.3)"
			>
				<g id="circelwijzer">
					<g id="arrow">
						<path
							d="M654.22,495.76C654.84,527.79 624.946,537.515 592.546,538.315C560.086,539.125 536.95,530.39 536.33,498.3C535.71,466.27 591.28,251.92 591.28,251.92C591.28,251.92 653.6,463.67 654.22,495.76Z"
							style="fill: rgb(124, 45, 0); fill-rule: nonzero"
						/>
						<path
							d="M590.6,254.28L595.202,530.658C595.096,506.663 594.899,529.634 595.185,530.625C595.212,530.641 595.241,530.648 595.27,530.647C627.699,529.845 654.47,527.898 653.85,495.89C653.23,463.86 590.97,251.74 590.97,251.74C590.97,251.74 591.34,252.6 590.6,254.28Z"
							style="fill: rgb(255, 51, 0); fill-rule: nonzero"
						/>
					</g>
				</g>
			</g>
			<g
				id="binnencirkel"
				transform="matrix(0.0371459,0,0,0.0380883,166.419,264.478)"
			>
				<path
					d="M9036.52,5454.8C6884.32,5454.8 5135.65,7218.41 5135.65,9385.56L12929.9,9385.56C12937.4,7218.41 11188.7,5454.79 9036.5,5454.79L9036.52,5454.79L9036.52,5454.8Z"
					style="fill: rgb(74, 78, 87); fill-rule: nonzero"
				/>
				<path
					d="M9036.52,6359.01C7377.53,6359.01 6032.41,7719.08 6032.41,9385.56L12040.7,9385.56C12048.1,7719.08 10695.5,6359.01 9036.54,6359.01L9036.52,6359.01Z"
					style="fill: rgb(237, 237, 237); fill-rule: nonzero"
				/>
				<rect
					id="score"
					x="8074.21"
					y="7351.35"
					width="1917.16"
					height="2026.36"
					style="fill: none"
				/>
			</g>
			<g
				id="Onderkant"
				transform="matrix(1.56285,0,0,1.60471,-280.825,-195.917)"
			>
				<g transform="matrix(1.00138,0,0,0.604511,0.571738,222.062)">
					<rect
						x="-0.395"
						y="509.091"
						width="1000"
						height="79.051"
						style="fill: rgb(237, 237, 237)"
					/>
				</g>
				<g transform="matrix(84.4494,0,0,1.36045,-38085.5,-79.7311)">
					<rect
						x="450.988"
						y="433.202"
						width="11.858"
						height="15.81"
						style="fill: rgb(74, 78, 87)"
					/>
				</g>
			</g>
		</g>
	</svg>
</template>

<script>
// imports
import { midPointerMeter } from "../scripts/meter.js";

export default {
	props: [`value`, "settings"],
	data() {
		return {
			meter: "no value",
			//end return
		};
		// end data
	},
	mounted() {
		this.runMeter();
	},
	watch: {
		value: function () {
			let oldValue;
			if (
				(this.value != oldValue && this.settings.manMode === false) ||
				(this.settings.manValue != oldValue && this.settings.manMode === true)
			) {
				if (this.settings.manMode === true) {
					this.runMeter();
					oldValue = this.settings.manValue;
				} else {
					this.runMeter();
					oldValue = this.value;
				}
			}
			// end watch function
		},
	},
	methods: {
		runMeter() {
			this.meter = midPointerMeter(this.value, this.settings);
			this.$emit("meter", this.meter);
			document.getElementById(
				"arrow"
			).style.transform = `rotate(${this.meter.pointerDeg}deg)`;
		},
	},
	// end export
};
</script>
