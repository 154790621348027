<template>
     <div class="d-flex justify-content-center">
    <h1 class="header-outro text-center" role="heading">
      Je persoonlijke ondernemers-check rapport is verstuurd naar het door jouw opgegeven mailadres.
    </h1>
  </div>
</template>

<script>
require('@/styles/outro.css')

export default {
    name: "outro",
};
</script>